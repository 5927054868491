<template>
  <v-container
    class="profile-update-container scroll-container"
    v-if="userData"
  >
    <CategoryTitle :category="category" class="mb-3" />

    <h5
      class="text-uppercase font-weight-bold secondary--text"
      v-html="$t('profile.update.customerCode')"
    />

    <v-form class="d-flex flex-wrap" ref="form">
      <v-row>
        <v-col cols="12" md="5">
          <label v-html="`${$t('profile.clientCode')}`" />
          <v-text-field
            class="mt-2"
            required
            outlined
            dense
            disabled
            v-model="userData.person.codInt"
          /> </v-col></v-row
    ></v-form>
    <v-divider class="primary mb-3"></v-divider>

    <h5
      class="text-uppercase font-weight-bold secondary--text"
      v-html="$t('profile.update.personalData')"
    />

    <!-- PERSONAL DATA - NOT MODIFIABLE -->
    <UserData
      ref="personalDataForm"
      :userData.sync="userData"
      :supportData.sync="supportData"
      :hasFidelityCard="true"
      :askFirstName="true"
      :askLastName="false"
      :askFiscalCode="true"
      :isProfileForm="true"
      :showButtons="false"
      :showFiscalCode="false"
      :isFieldDisabled="true"
      @validate="validate"
    />
    <UserAddress
      ref="addressDataForm"
      :userData.sync="userData"
      :supportData.sync="supportData"
      :isFieldDisabled="true"
      :isProfileForm="true"
      :showButtons="false"
    />
    <v-checkbox
      v-model="disclaimer1"
      hide-details
      disabled
      class="disclaimer-checkbox"
      true-value="true"
      false-value="false"
      ><div slot="label">
        <span v-if="userData.fidelity">*</span>{{ $t("profile.disclaimer1") }}
      </div></v-checkbox
    >
    <v-checkbox
      v-model="disclaimer2"
      hide-details
      disabled
      :label="$t('profile.disclaimer2')"
      class="disclaimer-checkbox"
      true-value="true"
      false-value="false"
    ></v-checkbox>
    <v-checkbox
      v-model="disclaimer3"
      hide-details
      class="disclaimer-checkbox"
      true-value="true"
      false-value="false"
      ><div slot="label">
        {{ $t("profile.disclaimer3") }}
        (<a
          @click.stop="$router.push('/p/web-policy/')"
          class="primary--text text-decoration-none"
          >web policy</a
        >)
      </div></v-checkbox
    >
    <div class="mt-3">* {{ $t("profile.requiredFields") }}</div>
    <div class="mb-3">
      ** {{ $t("profile.requestChangesNote") }} (<span
        class="primary--text text-decoration-none"
        style="cursor: pointer"
        @click="$router.push('/p/contatti/')"
        >Contatti</span
      >)
    </div>
    <!-- UPDATE PERSONAL DATA -->
    <v-col class="pa-0 ml-auto" cols="12" md="5">
      <v-btn
        color="primary"
        x-large
        depressed
        block
        v-html="$t('profile.updatePersonalData')"
        @click="validate"
      />
    </v-col>

    <v-divider class="primary my-3"></v-divider>
    <h5
      class="text-uppercase font-weight-bold px-0 secondary--text"
      v-html="`Modifica PREFERENZA CONTATTO`"
    />
    <v-form ref="contactPreferform" v-model="isEmailFormValid">
      <v-row class="my-3 col-sm-6 col-6">
        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="contactPreferPersonInfos.whatsapp"
            hide-details="auto"
            class="disclaimer-checkbox"
            required
            ><div slot="label">
              <v-icon style="width: 32px;">$whatsapp1</v-icon>
              <label class="contact-preferences" style="margin-left: 10px;"
                >WhatsApp
              </label>
            </div></v-checkbox
          >
        </v-col>

        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="contactPreferPersonInfos.pushNotify"
            hide-details="auto"
            class="disclaimer-checkbox"
            required
            ><div slot="label">
              <v-icon style="width: 32px;">$notifichePush</v-icon>
              <label class="contact-preferences" style="margin-left: 10px;"
                >Notifiche Push
              </label>
            </div></v-checkbox
          >
        </v-col>
        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="contactPreferPersonInfos.sms"
            hide-details="auto"
            class="disclaimer-checkbox"
            required
            ><div slot="label">
              <v-icon style="width: 32px;">$sms</v-icon>

              <label class="contact-preferences" style="margin-left: 10px;"
                >Sms
              </label>
            </div></v-checkbox
          >
        </v-col>
        <v-col cols="12" sm="6">
          <v-checkbox
            v-model="contactPreferPersonInfos.email"
            hide-details="auto"
            class="disclaimer-checkbox"
            required
            ><div slot="label">
              <v-icon style="width: 32px;">$email1</v-icon>
              <label class="contact-preferences" style="margin-left: 10px;"
                >E-mail
              </label>
            </div></v-checkbox
          >
        </v-col>
      </v-row>

      <v-col class="pa-0 ml-auto" cols="12" md="5">
        <v-btn
          color="primary"
          x-large
          depressed
          block
          @click="updatePreferContact()"
          :loading="loadingPreferContact"
        >
          Modifica PREFERENZA CONTATTO
        </v-btn>
      </v-col>
    </v-form>

    <v-divider class="primary my-3"></v-divider>

    <!-- UPDATE EMAIL -->
    <h5
      class="text-uppercase font-weight-bold px-0 secondary--text"
      v-html="`${$t('profile.edit.email')}`"
    />
    <v-form ref="emailform" v-model="isEmailFormValid">
      <v-row class="my-3">
        <v-col cols="12" sm="6">
          <!-- old email -->
          <label v-html="`${$t('profile.email.currentEmail')}`" />
          <v-text-field
            v-model="userData.email"
            :placeholder="`${$t('profile.email.currentEmail')}`"
            outlined
            dense
            disabled
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- new email -->
          <label v-html="`${$t('profile.email.newEmail')}*`" />
          <v-text-field
            v-model="newEmail"
            :placeholder="`${$t('profile.email.newEmail')}*`"
            :rules="emailRules"
            required
            outlined
            dense
          />
        </v-col>
      </v-row>
      <span>* {{ $t("profile.requiredFields") }}</span>
      <v-col class="pa-0 ml-auto" cols="12" md="5">
        <v-btn
          color="primary"
          x-large
          depressed
          block
          :disabled="!isEmailFormValid"
          @click="updateEmail()"
          :loading="loadingEmailChange"
        >
          {{ $t("profile.updateEmailData") }}
        </v-btn>
      </v-col>
    </v-form>

    <v-divider class="primary my-3"></v-divider>

    <!-- UPDATE SECONDARY EMAIL -->
    <h5
      class="text-uppercase font-weight-bold px-0 secondary--text"
      v-html="`${$t('profile.edit.secondaryEmail')}`"
    />
    <v-form ref="secondaryemailform" v-model="isSecondaryEmailFormValid">
      <v-row class="my-3">
        <v-col cols="12" sm="6">
          <!-- old secondary email -->
          <label v-html="`${$t('profile.email.currentSecondaryEmail')}`" />
          <v-text-field
            v-model="userData.contact.email2"
            :placeholder="`${$t('profile.email.currentSecondaryEmail')}`"
            outlined
            dense
            disabled
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- new secondary email -->
          <label v-html="`${$t('profile.email.newSecondaryEmail')}*`" />
          <v-text-field
            v-model="newSecondaryEmail"
            :placeholder="`${$t('profile.email.newSecondaryEmail')}*`"
            :rules="emailRules"
            required
            outlined
            dense
          />
        </v-col>
      </v-row>
      <span>* {{ $t("profile.requiredFields") }}</span>
      <v-col class="pa-0 ml-auto" cols="12" md="5">
        <v-btn
          color="primary"
          x-large
          depressed
          block
          :disabled="!isSecondaryEmailFormValid"
          @click="updateSecondaryEmail()"
          :loading="loadingSecondaryEmailChange"
        >
          {{ $t("profile.updateEmailData") }}
        </v-btn>
      </v-col>
    </v-form>

    <v-divider class="primary my-3"></v-divider>

    <!-- UPDATE PASSWORD -->
    <h5
      class="text-uppercase font-weight-bold px-0 secondary--text"
      v-html="`${$t('profile.edit.password')}`"
    />
    <v-form ref="passwordform" v-model="isPasswordFormValid">
      <v-row class="my-3">
        <v-col cols="12" sm="5">
          <!-- password -->
          <label v-html="`${$t('profile.oldPassword')}*`" />
          <v-text-field
            v-model="oldPassword"
            :placeholder="`${$t('profile.oldPassword')}`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          />
        </v-col>
        <v-col cols="0" sm="5"> </v-col>
        <v-col cols="12" sm="5">
          <!-- password -->
          <label v-html="`${$t('profile.password')}*`" />
          <v-text-field
            v-model="password"
            :placeholder="`${$t('profile.password')}*`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" sm="5">
          <!-- conferma password -->
          <label v-html="`${$t('profile.passwordConfirm')}*`" />
          <v-text-field
            :placeholder="`${$t('profile.passwordConfirm')}*`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          />
        </v-col>
      </v-row>
      <span>* {{ $t("profile.requiredFields") }}</span>
      <v-col class="pa-0 ml-auto" cols="12" md="5">
        <v-btn
          color="primary"
          x-large
          depressed
          block
          :disabled="!isPasswordFormValid"
          @click="updatePassword()"
          :loading="loadingPasswordChange"
        >
          {{ $t("profile.updatePassword") }}
        </v-btn>
      </v-col>
    </v-form>

    <v-divider class="primary my-3"></v-divider>

    <!-- DELETE ACCOUNT -->
    <div class="anonymize-block mt-10 px-0">
      <h3
        class="secondary--text"
        v-html="$t('profile.header.anonymizeAccount')"
      />
      <span
        >Tramite questa funzione il tuo account verrà eliminato e non potrai più
        fare ordini<br />
        L'opzione è disponibile solo se non hai ordini da ritirare
      </span>
      <ResponseMessage class="mt-3" :response="responseAnonymazeAccount" />
      <div
        no-gutters
        class="d-flex justify-center justify-md-start mt-3 mt-sm-6"
      >
        <v-btn
          large
          color="primary"
          depressed
          min-width="250"
          class="text-uppercase mb-2"
          @click="anonymizeAccount()"
          :loading="loadingAnonymizeAccount"
          >{{ $t("profile.anonymizeAccountBtn") }}
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="removeDialog" max-width="400">
      <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div>
      <v-card>
        <v-card-title class="anonymize-dialog-title text-h2">
          {{ $t("profile.anonymizeProfileDialog.title") }}
        </v-card-title>
        <v-card-text
          class="anonymize-dialog-text text-body-1"
          style="font-size: 14px !important"
          v-html="$t('profile.anonymizeProfileDialog.text')"
        >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            @click.prevent="anonymizeAccount(true)"
            @mousedown.stop
          >
            {{ $t("common.yes") }}
          </v-btn>
          <v-btn class="primary" depressed text @click="removeDialog = false">
            {{ $t("common.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RecaptchaDisclaimer />
  </v-container>
</template>
<style lang="scss">
.profile-update-container {
  h2 {
    font-size: 30px;
  }
  a {
    color: var(--v-default-base);
  }
  .v-autocomplete {
    box-shadow: none;
    -webkit-box-shadow: none;
    input {
      font-size: 16px !important;
    }
  }
  .disclaimer-checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
  .contact-preferences {
    color: black;
    line-height: 1.5;
    font-family: "Roboto", arial, sans-serif;
  }
}
</style>
<script>
import {
  requiredValue,
  isEmail,
  isNumber,
  minLength,
  minCapitalCharacter,
  minSymbolCharacter
} from "~/validator/validationRules";

import { getPersonInfo } from "~/service/ebsn";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import UserData from "@/components/profile/form/UserData.vue";
import UserAddress from "@/components/profile/form/UserAddress.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import categoryMixins from "~/mixins/category";
//import { setPersonInfo } from "~/service/ebsn";
import UserService from "~/service/userService";
import GeoService from "~/service/geoService";
import RecaptchaDisclaimer from "~/components/RecaptchaDisclaimer.vue";

import { mapPersonInfo } from "~/service/ebsn";
import { mask } from "vue-the-mask";

import userService from "~/service/userService";
import CustomService from "@/service/customService";
import { mapActions } from "vuex";

export default {
  name: "ProfileUpdate",
  components: {
    CategoryTitle,
    UserData,
    UserAddress,
    ResponseMessage,
    RecaptchaDisclaimer
  },
  mixins: [categoryMixins],
  directives: { mask },
  data() {
    return {
      contactPreferPersonInfos: {
        whatsapp: false,
        pushNotify: false,
        sms: false,
        email: false
      },
      isValid: true,
      birthdateMenu: false,
      showPassword: false,
      oldPassword: null,
      loadingPasswordChange: false,
      password: null,
      disclaimerList: null,
      userDisclaimer: null,
      registrationModules: null,
      isDisclaimerFormValid: false,
      loadingDisclaimerChange: false,
      disclaimerToChange: null,
      disclaimerOptions: {
        color: "primary"
      },
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      dateRules: [requiredValue("Digitare la data di nascita")],
      isEmail: isEmail,
      isNumber: isNumber,
      fidelityCardRules: [
        requiredValue(),
        isNumber("Deve contenere solo cifre")
      ],
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(8, "La password deve essere lunga almeno 8 caratteri"),
        minCapitalCharacter(),
        minSymbolCharacter()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ],
      userData: {
        person: {},
        contact: {}
      },
      supportData: {
        privacy1: true,
        privacy2: true,
        country: {
          itemId: null
        }
      },
      billingAddress: {},
      provinces: [],
      response: {},
      loadingAnonymizeAccount: false,
      removeDialog: false,
      responseAnonymazeAccount: {},
      isPasswordFormValid: false,
      isEmailFormValid: false,
      isSecondaryEmailFormValid: false,
      newEmail: null,
      newSecondaryEmail: null,
      loadingEmailChange: false,
      loadingPreferContact: false,
      loadingSecondaryEmailChange: false
    };
  },
  computed: {
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    },
    ...mapPersonInfo(
      { disclaimer1: 1, disclaimer2: 2, disclaimer3: 3 },
      "userData.person"
    )
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout"
    }),
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async getUserData() {
      const result = await UserService.getUserDetail();
      if (result) {
        this.userData = result;
        this.userData.person.gender = this.userData.person.gender
          ? this.userData.person.gender
          : "M";
        // workaround per forzare il popolamento del campo country
        /* let country = global.config.customCountries.find(c => {
          return c.itemId === this.userData.billingAddress.countryId;
        });*/
        //this.$refs.addressDataForm.changeCountry(country, false);
        //this.loadDisclaimer();
        this.loadPreferContact();
      }
    },
    async validate() {
      let personalData = this.$refs.personalDataForm.$refs.form;
      let addressData = this.$refs.addressDataForm.$refs.form;
      let userAddressCoords = await GeoService.geocodeAddress(
        this.userData.billingAddress
      );

      console.log(userAddressCoords);
      console.log(personalData.validate(), addressData.validate());
      if (personalData.validate() && addressData.validate()) {
        this.updateProfile();
      }
      const wId = this.userData.defaultStoreAddressId?.warehouseId;
      if (wId) {
        this.userData.defaultStoreAddressId = wId;
      }
    },
    back() {
      this.$router.back();
    },
    async updateProfile() {
      let _this = this;
      if (typeof this.userData.billingAddress.province === "object") {
        this.userData.billingAddress.province = this.userData.billingAddress.province.name;
      }
      try {
        let userAddressCoords = await GeoService.geocodeAddress(
          this.userData.billingAddress
        );

        console.log(userAddressCoords);
        _this.response = {};
        let res = await CustomService.updateUserDetail(_this.userData);
        if (res) {
          // this.loadCart();
        }
      } catch (response) {
        _this.response = response;
      }
    },
    async updatePassword() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingPasswordChange = true;
        if (_this.$refs.passwordform.validate()) {
          let res = await userService.updatePassword(
            _this.oldPassword,
            _this.password
          );
          _this.loadingPasswordChange = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingPasswordChange = false;
      }
    },

    async updatePreferContact() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingPreferContact = true;
        if (_this.contactPreferPersonInfos != null) {
          let res = await CustomService.updatePreferContact(
            _this.contactPreferPersonInfos
          );
          _this.loadingPreferContact = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingPreferContact = false;
      }
    },
    async updateEmail() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingEmailChange = true;
        if (_this.$refs.emailform.validate()) {
          let res = await userService.updateEmailRequest(_this.newEmail);
          _this.loadingEmailChange = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingEmailChange = false;
      }
    },
    async updateSecondaryEmail() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingSecondaryEmailChange = true;
        if (_this.$refs.secondaryemailform.validate()) {
          if (!_this.userData.contact) {
            _this.userData.contact = {};
          }
          _this.userData.contact.email2 = _this.newSecondaryEmail;
          let res = await userService.updateContact(_this.userData);
          _this.loadingSecondaryEmailChange = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingSecondaryEmailChange = false;
      }
    },
    async updateDisclaimers() {
      let vm = this;
      try {
        vm.loadingDisclaimersChange = true;
        if (vm.$refs.disclaimerform.validate()) {
          if (vm.disclaimerToChange) {
            let registrationModulesParam = vm.disclaimerToChange.map(
              disclaimer => {
                let obj = {
                  registrationDisclaimer: {
                    registrationDisclaimerId:
                      disclaimer.registrationDisclaimerId
                  },
                  value: disclaimer.value
                };
                if (disclaimer.registrationModuleDisclaimerId) {
                  obj.registrationModuleDisclaimerId =
                    disclaimer.registrationModuleDisclaimerId;
                }
                return obj;
              }
            );

            if (vm.userDisclaimer && vm.userDisclaimer.registrationModules) {
              vm.userDisclaimer.registrationModules[0].registrationModuleDisclaimers = registrationModulesParam;
            } else {
              vm.userDisclaimer = {
                registrationModules: [
                  {
                    registrationModuleAction: {
                      registrationModuleActionId: 1,
                      name: "Inserimento"
                    },
                    registrationModuleType: {
                      registrationModuleTypeId: 2,
                      name: "REG_WEB",
                      description: ""
                    },
                    addDate: new Date().toISOString(),
                    registrationModuleDisclaimers: registrationModulesParam
                  }
                ]
              };
            }

            let result = await UserService.updateUserDisclaimers(
              vm.userDisclaimer.registrationModules[0]
            );
            if (
              result &&
              result.registrationModules &&
              result.registrationModules.length > 0
            ) {
              this.userDisclaimer = result;
            }

            vm.loadingDisclaimersChange = false;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        vm.loadingDisclaimersChange = false;
      }
    },

    async loadDisclaimer() {
      let storeId = 5;
      let registrationModuleTypeId = 2;
      let res = await UserService.getRegistrationDisclaimerList(
        registrationModuleTypeId,
        storeId
      );
      const disclaimers = res.registrationDisclaimers;
      this.disclaimerList = disclaimers.sort((a, b) => a.priority - b.priority);
    },

    async getDisclaimerList() {
      let vm = this;
      let result = await UserService.getRegistrationDisclaimerList(2, 5);
      if (result && result.registrationDisclaimers) {
        vm.disclaimerList = result.registrationDisclaimers;
      }
    },

    /* disclaimerChanged(registrationModules) {
      this.registrationModules = registrationModules;
    },*/

    disclaimerChanged(userDisclaimer) {
      this.disclaimerToChange = userDisclaimer;
    },
    async getUserDisclaimer(registrationModuleTypeId, storeId) {
      let result = await UserService.getUserDisclaimerListByType(
        registrationModuleTypeId,
        storeId
      );
      if (
        result &&
        result.registrationModules &&
        result.registrationModules.length > 0 &&
        result.registrationModules[0].registrationModuleDisclaimers
      ) {
        this.userDisclaimer = result;

        result.registrationModules[0].registrationModuleDisclaimers.forEach(
          disclaimer => {
            let disclaimerFromList = this.disclaimerList.find(
              item =>
                item.registrationDisclaimerId ===
                disclaimer.registrationDisclaimer.registrationDisclaimerId
            );
            if (disclaimerFromList) {
              disclaimerFromList.value = disclaimer.value;
              disclaimerFromList.registrationModuleDisclaimerId =
                disclaimer.registrationModuleDisclaimerId;
            }
          }
        );
      }
      console.log(
        "REG MODULS ******",
        this.registrationModules,
        "this.displacimer list",
        this.disclaimerList
      );
      this.registrationModules = this.disclaimerList;
    },

    async anonymizeAccount(perfom = false) {
      let _this = this;
      this.removeDialog = true;
      if (perfom) {
        this.loadingAnonymizeAccount = true;
        let result = await UserService.anonymizeAccount();
        if (result.response.status === 0) {
          this.removeDialog = false;
          // se utente davvero anonimizzato -> logout
          await _this.doLogout();
          _this.loadingAnonymizeAccount = false;
          _this.$router.push({ name: "Home" });
        } else {
          _this.responseAnonymazeAccount = result.response;
          _this.removeDialog = false;
          _this.loadingAnonymizeAccount = false;
        }
      }
    },

    async loadPreferContact() {
      let _this = this;
      let whatsapp = getPersonInfo(_this.userData.person, 100);
      let pushNotify = getPersonInfo(_this.userData.person, 101);
      let sms = getPersonInfo(_this.userData.person, 102);
      let email = getPersonInfo(_this.userData.person, 103);

      _this.contactPreferPersonInfos = {
        whatsapp: whatsapp != null ? whatsapp.toLowerCase() === "true" : null,
        pushNotify:
          pushNotify != null ? pushNotify.toLowerCase() === "true" : null,
        sms: sms != null ? sms.toLowerCase() === "true" : null,
        email: email != null ? email.toLowerCase() === "true" : null
      };
    }
  },
  async mounted() {
    await this.getUserData();
    await this.loadDisclaimer();
    //await userService.getUserDisclaimerListByType();
    await this.getUserDisclaimer();
    // await this.loadPreferContact();
  }
};
</script>
